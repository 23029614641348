/* eslint-disable global-require */
const MAP = {};

MAP.DEFAULT = {
  MAIN_LOGO: require('../assets/images/_Default/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_Default/logo-sidebar.jpg'),
};

MAP.ADIAM = {
  MAIN_LOGO: require('../assets/images/_Adiam/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_Adiam/logo-sidebar.png'),
};

MAP.ALLURE_DIAM = {
  MAIN_LOGO: require('../assets/images/_AllureDiam/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_AllureDiam/logo-sidebar.png'),
};

MAP.ANITA_DIAMONDS = {
  MAIN_LOGO: require('../assets/images/_AnitaDiamonds/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_AnitaDiamonds/logo-sidebar.png'),
};

MAP.CAPITAL_DIAMONDS = {
  MAIN_LOGO: require('../assets/images/_CapitalDiamonds/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_CapitalDiamonds/logo-sidebar.jpg'),
};

MAP.DIAMCAD = {
  MAIN_LOGO: require('../assets/images/_Diamcad/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_Diamcad/logo-sidebar.png'),
};

MAP.DIAMOND_CREATIONS_NV = {
  MAIN_LOGO: require('../assets/images/_DiamondCreationsNv/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_DiamondCreationsNv/logo-sidebar.png'),
};

MAP.DIAMWILL = {
  MAIN_LOGO: require('../assets/images/_Diamwill/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_Diamwill/logo-sidebar.png'),
};

MAP.GEM_QUEST = {
  MAIN_LOGO: require('../assets/images/_GemQuest/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_GemQuest/logo-sidebar.jpg'),
};

MAP.INTRALCOR = {
  MAIN_LOGO: require('../assets/images/_Intralcor/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_Intralcor/logo-sidebar.png'),
};

MAP.JK_DIAMOND = {
  MAIN_LOGO: require('../assets/images/_JkDiamond/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_JkDiamond/logo-sidebar.png'),
};

MAP.MSD_NV = {
  MAIN_LOGO: require('../assets/images/_MsdNv/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_MsdNv/logo-sidebar.jpg'),
};

MAP.PRISTINE_DIAMONDS = {
  MAIN_LOGO: require('../assets/images/_PristineDiamonds/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_PristineDiamonds/logo-sidebar.png'),
};

MAP.RUBIN_AND_ZONEN = {
  MAIN_LOGO: require('../assets/images/_RubinAndZonen/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_RubinAndZonen/logo-sidebar.jpg'),
};

MAP.SAMIR_GEMS = {
  MAIN_LOGO: require('../assets/images/_SamirGems/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_SamirGems/logo-sidebar.png'),
};

MAP.STAR_GEMS = {
  MAIN_LOGO: require('../assets/images/_StarGemsGroup/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_StarGemsGroup/logo-sidebar.png'),
};

MAP.VAN_DEN_PLAS_ZONEN_BVBA = {
  MAIN_LOGO: require('../assets/images/_VanDenPlasZonenBvba/logo.jpg'),
  SIDEBAR_LOGO: require('../assets/images/_VanDenPlasZonenBvba/logo-sidebar.jpg'),
};

MAP.YAELSTAR_BV = {
  MAIN_LOGO: require('../assets/images/_YaelstarBv/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_YaelstarBv/logo-sidebar.png'),
};

MAP.SOPHIA_DIAMONDS = {
  MAIN_LOGO: require('../assets/images/_SophiaDiamonds/logo.png'),
  SIDEBAR_LOGO: require('../assets/images/_SophiaDiamonds/logo-sidebar.png'),
};

const OUT = MAP[process.env.REACT_APP_ID] || MAP.DEFAULT;
module.exports = OUT;
