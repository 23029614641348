import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';

import config from 'config/_config.json';

export const BASE_URL = config.url.API_URL;
export const MEDIA_URL = config.url.MEDIA_URL;
export const FRONT_URL = config.url.FRONT_URL;
export const COMMON_URL = { BASE_URL, MEDIA_URL, FRONT_URL };

export const PREFIX_URL = kebabCase(lowerCase(config.common.COMPANY_NAME));
export const PREFIX_URL_WITH_SLASH = `/${PREFIX_URL}`;
