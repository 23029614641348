import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { classNames } from 'util/classNames';

import loaderSvg from 'assets/images/loader.svg';

function CircularProgress({ className, message, loader, visible, ...props }) {
  const loading = useSelector((state) => state?.loader?.loading);

  return loading ? null : (
    <div className={classNames([`loader`, className])} {...props}>
      {message ? <span>{message}</span> : <img src={loaderSvg} alt="loader" />}
    </div>
  );
}

CircularProgress.propTypes = {
  message: PropTypes.node,
};

export default React.memo(CircularProgress);
