import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import { history } from 'util/history';

import loader from 'appRedux/reducers/LoaderReducer';
import bidConfig from 'appRedux/reducers/bidConfig';
import serverTime from 'appRedux/reducers/serverTime';

import AccountAddress from './AccountAddress';
import AccountFilter from './AccountFilter';
import Auth from './Auth';
import ClientDetail from './ClientDetail';
import Common from './Common';
import FilterReducer from './FilterReducer';
import Settings from './Settings';
import TabReducer from './TabIssueReducer/TabReducer';
import TermsDetail from './TermsDetail';
import User from './User';
import UserFilter from './UserFilter';
import diamond from './diamond';
import permission from './permission';

const reducers = combineReducers({
  loader,
  bidConfig,
  serverTime,

  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  permission: permission,
  client: ClientDetail,
  tab: TabReducer,
  accountAddress: AccountAddress,
  user: User,
  accountFilter: AccountFilter,
  userFilter: UserFilter,
  diamondData: diamond,
  termsDetail: TermsDetail,
  filter: FilterReducer,
});

export default reducers;
