import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';

/**
 * Fucntion used to get user list
 * @param {*} request
 */
export const getUserList = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.User.paginate, request });

export const getUserDetails = (id) =>
  UtilService.callApiAsync({ ...UtilService.withUrlParams(API_ROUTES.User.getDetail, [id]) });

export const downloadUserExcel = (request = {}) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.downloadExcel, request });

/**
 * Function used to get user details
 * @param {*} id
 */
export const geUserDetails = (id = null) =>
  UtilService.callApiAsync({ ...UtilService.withUrlParams(API_ROUTES.User.getDetail, [id]) });

/**
 * Function used to update user details
 * @param {*} id
 * @param {*} request
 */
export const updateUserDetail = (id = null, request = {}) =>
  UtilService.callApiAsync({ ...UtilService.withUrlParams(API_ROUTES.User.update, [id]), request });

export const handleUserPermission = (id = null, request = {}) => {
  const requests = {
    userId: id,
  };
  UtilService.callApiAsync({ ...UtilService.withUrlParams(API_ROUTES.UserPermit.Paginate), requests });
};

/**
 *  Function used to save user details
 * @param {*} request
 */
export const storeUserDetail = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.User.Upsert, request });

/**
 *  Function used to save user details
 * @param {*} request
 */
export const createMany = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.User.createMany, request });

/**
 * Fucntion used to change password
 * @param {*} request
 */
export const resetPassword = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.User.ResetPassword, request });

/**
 * Fucntion used take highEnd clients
 * @param {*} request
 */
export const getHighEndClient = (request = {}) =>
  UtilService.callApiAsync({ ...API_ROUTES.Account.getHighEndClient, request });

/**
 * get token from user id
 * @param {*} request
 */
export const getUserToken = (request = {}) => UtilService.callApiAsync({ ...API_ROUTES.User.generateToken, request });

/**
 * resend verification email to user
 * @param {*} request
 */
export const resendVerificationEmail = (request = {}) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.resenVerificationEmail, request });

/**
 * sync user manually
 * @param {*} request
 */
export const syncUserManually = (request = {}) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.manualUserSync, request });
