import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import invert from 'lodash/invert';

import { Storage } from 'services/storage';

import { i18n } from 'util/i18n';
import { isEmpty, isArray } from 'util/utils';

import { MENU } from './Menu';

export const getPermissions = (spread) => ({
  view: true,
  insert: true,
  update: true,
  delete: true,
  uploadExcel: true,
  downloadExcel: true,
  mailExcel: true,
  printPDF: true,
  all: true,
  ...spread,
});

export const USER_PERMISSION = [
  { module: 'DASHBOARD', title: 'Dashboard', permissions: getPermissions() },
  { module: 'SEARCH_DIAMOND', title: 'Search Diamond', permissions: getPermissions() },
  { module: 'QUICK_SEARCH', title: 'Quick Search', permissions: getPermissions() },
  { module: 'FANCY_SEARCH', title: 'Fancy Search', permissions: getPermissions() },
  { module: 'SAVE_SEARCH', title: 'Save Search', permissions: getPermissions() },
  { module: 'DEMAND', title: 'Demand', permissions: getPermissions() },
  { module: 'ENQUIRY', title: 'Enquiry', permissions: getPermissions() },
  { module: 'SEARCH_LIST', title: 'Search List', permissions: getPermissions() },
  { module: 'COMPARE', title: 'Compare', permissions: getPermissions() },
  { module: 'MATCH_PAIR', title: 'Match Pair', permissions: getPermissions() },
  { module: 'BID', title: 'BID IT', permissions: getPermissions() },
  { module: 'NEW_ARRIVAL', title: 'New Arrival', permissions: getPermissions() },
  { module: 'EXCLUSIVE', title: 'Exclusive', permissions: getPermissions() },
  { module: 'UPCOMING', title: 'Upcoming Diamonds', permissions: getPermissions() },
  { module: 'CART', title: 'Cart', permissions: getPermissions() },
  { module: 'HOLD', title: 'Hold', permissions: getPermissions() },
  { module: 'MEMO', title: 'Memo', permissions: getPermissions() },
  { module: 'WATCHLIST', title: 'Watchlist', permissions: getPermissions() },
  { module: 'NOTES', title: 'Notes', permissions: getPermissions() },
  { module: 'CONFIRM_STONE', title: 'Confirm Stone', permissions: getPermissions() },
  { module: 'SHIPMENT', title: 'Shipment', permissions: getPermissions() },
  { module: 'OFFER_DAY', title: 'Offer Day', permissions: getPermissions() },
  { module: 'PRINT', title: 'Print', permissions: getPermissions() },
  { module: 'OFFER', title: 'Quote', permissions: getPermissions() },
  { module: 'MY_BID', title: 'My Bid', permissions: getPermissions() },
  { module: 'OFFICE', title: 'Office', permissions: getPermissions() },
  { module: 'FINAL_CALCULATIONS', title: 'Final Calculations', permissions: getPermissions() },
  { module: 'SHARE_VIA_MAIL', title: 'Share via Email', permissions: getPermissions() },
  { module: 'SHARE_VIA_WHATSAPP', title: 'Share via WhatsApp', permissions: getPermissions() },
  { module: 'SHARE_VIA_SKYPE', title: 'Share via Skype', permissions: getPermissions() },
  { module: 'SHARE_VIA_WECHAT', title: 'Share via WeChat', permissions: getPermissions() },
  { module: 'DOWNLOAD', title: 'Download', permissions: getPermissions() },
  { module: 'NOTIFICATIONS', title: 'Notification', permissions: getPermissions() },
  { module: 'REMINDER', title: 'Reminder', permissions: getPermissions() },
  { module: 'SUGGESTED_STOCK', title: 'Suggested stock', permissions: getPermissions() },
  { module: 'SEARCH_MATCH_PAIR', title: 'Search Match Pair', permissions: getPermissions() },
  { module: 'HIDE_GRID_COLUMNS', title: 'Hide Grid Columns', permissions: { org: false, ftc: false, mines: false } },
  { module: 'STONE_OF_THE_DAY', title: 'Stone Of The Day', permissions: getPermissions() },
  { module: 'COLLECTION', title: 'Collection', permissions: getPermissions() },
];

export const PERMISSION = [
  {
    mainModule: 'CLIENT',
    subModule: 'ANALYTICS',
    module: 'ANALYTICS',
    title: 'Analytics',
    url: '/client/analytics',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'CONFIRM_STONE',
    module: 'APPROVED',
    title: 'Approved',
    url: '/client/order/approved',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'CONFIRM_STONE',
    module: 'PENDING',
    title: 'Pending',
    url: '/client/order/pending',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'CONFIRM_STONE',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/order/rejected',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ENQUIRY',
    module: 'DEMAND',
    title: 'Demand',
    url: '/client/enquiry/demand',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ENQUIRY',
    module: 'HOLD',
    title: 'Hold',
    url: '/client/enquiry/hold',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ENQUIRY',
    module: 'MEMO',
    title: 'Memo',
    url: '/client/enquiry/memo',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BANKS',
    title: 'Banks',
    url: '/client/kyc/bank-info',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BRANCH',
    title: 'Branch',
    url: '/client/kyc/branch',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BUSINESS_DETAILS',
    title: 'Business Details',
    url: '/client/kyc/business-detail',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'GENERAL',
    title: 'General',
    url: '/client/kyc/basic',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'REFERENCES',
    title: 'References',
    url: '/client/kyc/references-detail',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'SETTING',
    title: 'Setting',
    url: '/client/kyc/setting',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'TERMS_DETAIL',
    title: 'Terms Details',
    url: '/client/kyc/terms-detail',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'USER',
    title: 'User',
    url: '/client/kyc/user',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/client/offer/approved',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'PENDING',
    title: 'Pending',
    url: '/client/offer/pending',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/offer/rejected',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'CLIENT_PREFERANCE',
    title: 'Client Preferance',
    url: '/recent-search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'RECENT_SEARCH',
    title: 'Recent Search',
    url: '/client/search/recent-search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/client/search/saved-search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SUGGESTED_STOCK',
    module: 'SUGGESTED_STOCK',
    title: 'Suggested Stock',
    url: '/client/suggested-stock',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'ACCURATE_STOCK',
    title: 'Accurate Stock',
    url: '/accurate-stock',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'CART',
    title: 'Cart',
    url: '/client/transactions/cart',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'NOTES',
    title: 'Notes',
    url: '/client/transactions/notes',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'OFFICE_VIEW',
    title: 'Office View',
    url: '/client/transactions/officeview',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'SEEN_STONES',
    title: 'Seen Stones',
    url: '/client/transactions/seen-stones',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'SUGGESTED_STOCK',
    title: 'Suggested Stock',
    url: '/suggested-stock',
    permissions: getPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/client/transactions/cart',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'DASHBOARD',
    url: '/dashboard/home',
    title: 'Dashboard',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'ENQUIRY',
    url: '/dashboard/enquiry',
    title: 'Enquiry',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'INVENTORY',
    url: '/dashboard/inventory',
    title: 'Inventory',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'SEARCH',
    title: 'Search',
    url: '/dashboard/search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'DAILY_VISIT_REPORT',
    title: 'Daily Visit Report',
    url: '/dashboard/analysis/daily-visit-report',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/dashboard/analysis/new-arrival-report',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'SALES_REPORT',
    title: 'Sales Report',
    url: '/dashboard/analysis/sales-report',
    permissions: getPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'USER_ACTIVITY_ANALYSIS',
    title: 'User Activity Analysis',
    url: '/dashboard/analysis/user-activity-analysis-report',
    permissions: getPermissions(),
  },
  {
    mainModule: 'ENQUIRY',
    module: 'CONFIRM',
    url: '/enquiry/confirm',
    permissions: getPermissions(),
  },
  {
    mainModule: 'ENQUIRY',
    module: 'CUSTOMER_HISTORY',
    url: '/enquiry/customer-history',
    permissions: getPermissions(),
  },
  {
    mainModule: 'ENQUIRY',
    module: 'NOTES',
    url: '/enquiry/notes',
    permissions: getPermissions(),
  },
  {
    mainModule: 'ENQUIRY',
    subModule: 'KYC',
    module: 'GENERAL',
    url: '/enquiry/kyc/basic',
    permissions: getPermissions(),
  },
  {
    mainModule: 'ENQUIRY',
    subModule: 'OFFER',
    module: 'APPROVED',
    url: '/transaction/offer/approved',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'ANALYTICS',
    module: 'ANALYTICS',
    title: 'Analytics',
    url: '/inventory/analytics',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'BID_CONFIG',
    title: 'Bidconfig',
    url: '/inventory/configuration/big-config',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CARAT_RANGE',
    title: 'Carat Range',
    url: '/inventory/configuration/carat-range',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CERTIFICATE_UPLOAD',
    title: 'Certificate Upload',
    url: '/inventory/configuration/upload-typeIIA-cert',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CHECK_IMAGE',
    title: 'Check Image',
    url: '/inventory/configuration/check-image-status',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CHECK_PACKET',
    title: 'Check Packet',
    url: '/inventory/configuration/check-packet',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CREATE_COLLECTIONS',
    title: 'Create Collections',
    url: '/inventory/configuration/create-collection',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CUT_GROUP',
    title: 'Cut Group',
    url: '/inventory/configuration/cut-group',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DIAMOND_COLUMN_SETTING',
    title: 'Diamond Column Setting',
    url: '/inventory/configuration/diamond-column-setting',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DOWNLOAD_EXCEL',
    title: 'Download Excel',
    url: '/inventory/configuration/download-Excel',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'SHARE_STOCK',
    title: 'Share Stock',
    url: '/inventory/configuration/share-stock',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'FEATURE_STONE',
    title: 'Feature Stone',
    url: '/inventory/configuration/feature-stone',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'MATCH_PAIR_CONFIGURE',
    title: 'Match Pair',
    url: '/inventory/configuration/match-pair-setting',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'OFFER_DAY',
    title: `${i18n.t('title.offer')} Day`,
    url: '/inventory/configuration/offer-day',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'RAPNET_POLICY',
    title: 'Rapnet Policy',
    url: '/inventory/configuration/rapnet-policy',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'TERMS_DETAILS',
    title: 'Terms Details',
    url: '/inventory/configuration/terms-details',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'VOLUME_DISCOUNT',
    title: 'Volume Discount',
    url: '/inventory/configuration/volume-discount',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    title: 'Sync With Online Inventory',
    module: 'SYNC_WITH_GLOBAL_INVENTORY',
    url: '/inventory/configuration/online-inventory',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MATCH_PAIR',
    title: 'Match Pair',
    url: '/inventory/single-stone/match-pair',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MY_DEMAND',
    title: 'My Demand',
    url: '/inventory/single-stone/my-demand',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/inventory/single-stone/new-arrival',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'OFFER_DAY',
    title: `${i18n.t('title.offer')} Day`,
    url: '/inventory/single-stone/offer-day',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'OVERVIEW',
    title: 'Overview',
    url: '/inventory/single-stone/overview',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'QUICK_SEARCH',
    title: 'Quick Search',
    url: '/inventory/single-stone/quick-search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/inventory/single-stone/saved-search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH_LAYOUT',
    title: 'Calibrated Diamond',
    url: '/inventory/single-stone/calibrated-diamond',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH',
    title: 'Search',
    url: '/inventory/single-stone/search',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SHOW_STONE_REPORT',
    title: 'Show Stone Report',
    url: '/inventory/single-stone/show-stone-report',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'UPCOMING',
    title: 'Upcoming',
    url: '/inventory/single-stone/upcoming',
    permissions: getPermissions({ isShowBack: true }),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'BLUENILE',
    title: 'BlueNile',
    url: '/inventory/stock-summary/bluenile',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'DIST_CRITERIA',
    title: 'Distribution Criteria',
    url: '/inventory/stock-summary/distribution-criteria',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'JAMESALLEN',
    title: 'James Allen',
    url: '/inventory/stock-summary/jamesallen',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'NO_DIST',
    title: 'Not Distributed',
    url: '/inventory/stock-summary/not-distributed',
    permissions: getPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'SUMMARY',
    title: 'Summary',
    url: '/inventory/stock-summary/overview',
    permissions: getPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'BULK_EMAIL',
    title: 'Bulk Email',
    url: '/notification/bulk-email',
    permissions: getPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'CLEARED',
    title: 'Cleared',
    url: '/seen',
    permissions: getPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'NEW',
    title: 'Unseen',
    url: '/unseen',
    permissions: getPermissions(),
  },
  // {
  //   mainModule: 'PRICING',
  //   module: 'REVISE',
  //   title: 'Revise',
  //   url: '/pricing/revise',
  //   permissions: getPermissions(),
  // },
  {
    mainModule: 'SETTING',
    module: 'MY_ACCOUNT',
    title: 'My Account',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    module: 'NOTIFICATION',
    title: 'Notification',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    module: 'TERMS_AND_CONDITIONS',
    title: 'Terms And Conditions',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'CONTACT_DIRECTORY',
    title: 'Contact Directory',
    url: '/setting/contact-directory',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'EMAIL_CONFIGURATION',
    title: 'Email Configuration',
    url: '/email-configuration',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'HOME_SETTING',
    title: 'Home Settings',
    url: '/setting/home-settings',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'MASTER',
    title: 'Master',
    url: '/setting/master',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'PROJECT_SETTING',
    title: 'Project Settings',
    url: '/project-settings',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'ROLE_PERMISSION',
    title: 'Role Permission',
    url: '/rolepermission',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'SUB_MASTER',
    title: 'Sub Master',
    url: '/setting/submaster',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'CLIENT_KYC',
    url: '/user/configuration/user-infomation',
    title: 'Clients / KYC',
    permissions: getPermissions(),
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'USER',
    url: '/user/configuration/user-infomation',
    title: 'User',
    permissions: getPermissions(),
  },
  {
    mainModule: 'STOCK_UPLOAD',
    subModule: 'STOCK_UPLOAD_CLIENT',
    title: 'Stock Upload',
    permissions: getPermissions(),
  },
  {
    mainModule: 'STOCK_UPLOAD',
    subModule: 'STOCK_UPLOAD_LIST',
    title: 'List',
    permissions: getPermissions(),
  },
  {
    mainModule: 'STOCK_UPLOAD',
    title: 'History',
    subModule: 'STOCK_HISTORY',
    permissions: getPermissions({
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      mailExcel: false,
      printPDF: false,
    }),
  },
  {
    mainModule: 'STOCK_UPLOAD',
    subModule: 'STOCK_UPLOAD_MEDIA',
    title: 'Media',
    permissions: getPermissions(),
  },
  {
    mainModule: 'STOCK_UPLOAD',
    subModule: 'STOCK_UPLOAD',
    title: 'Upload',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'CLOSED',
    title: 'Closed',
    url: '/todo/kyc/closed',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'INPROGRESS',
    title: 'In Progress',
    url: '/todo/kyc/inprogress',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'PENDING',
    title: 'Pending',
    url: '/todo/kyc/pending',
    permissions: getPermissions(),
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   module: 'TRACK_SHIPMENT',
  //   title: 'Ok',
  //   url: '/transaction/track-shipment',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'BID',
  //   module: 'HISTORY',
  //   title: 'History',
  //   url: '/transaction/bid/history',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'BID',
  //   module: 'MYCLIENTBID',
  //   title: 'My Client Bid',
  //   url: '/transaction/bid/my-client-bid',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'BID',
  //   module: 'RESULTS',
  //   title: 'Results',
  //   url: '/results',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'BID',
  //   module: 'RUNNING',
  //   title: 'Running',
  //   url: '/transaction/bid/running',
  //   permissions: getPermissions(),
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/order/approved',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/order/pending',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CONFIRM_STONE',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/order/rejected',
    permissions: getPermissions(),
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'C',
  //   title: 'C',
  //   url: '/transaction/report/c',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'E',
  //   title: 'E',
  //   url: '/transaction/report/e',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'S',
  //   title: 'S',
  //   url: '/transaction/report/s',
  //   permissions: getPermissions(),
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'CART',
    title: 'Cart',
    url: '/transaction/enquiry/cart',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_CLOSE',
    title: 'Enquiry Close',
    url: '/transaction/enquiry/enquiry-close',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_OPEN',
    title: 'Enquiry Open',
    url: '/transaction/enquiry/enquiry-open',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'NOTES',
    title: 'Notes',
    url: '/transaction/enquiry/notes',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'REMINDER',
    title: 'Reminder',
    url: '/reminder',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'SEEN_STONES',
    title: 'Seen Stones',
    url: '/transaction/enquiry/seen-stones',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/transaction/enquiry/watchlist',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/hold/approved',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/hold/pending',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/hold/rejected',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'HOLD',
    module: 'RELEASED',
    title: 'Released',
    url: '/transaction/hold/released',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'CONSIGNMENT',
    title: 'Consignment',
    url: '/transaction/memo/consignment',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'MEMO',
    module: 'OFFICE',
    title: 'Office',
    url: '/transaction/memo/office',
    permissions: getPermissions({ confirm: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/offer/approved',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'COUNTER',
    title: 'Counter Offer',
    url: '/transaction/offer/counter-offer',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/offer/pending',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/offer/rejected',
    permissions: getPermissions({ isShowClientColumn: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/office-view/approved',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'EXPIRED',
    title: 'Expired',
    url: '/transaction/office-view/expired',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'PENDING',
    title: 'Upcoming',
    url: '/transaction/office-view/upcoming',
    permissions: getPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/office-view/rejected',
    permissions: getPermissions(),
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'PROFORMA_REPORT',
  //   module: 'OK',
  //   title: 'Ok',
  //   url: '/ok',
  //   permissions: getPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'PROFORMA_REPORT',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/transaction/proforma-report/pending',
  //   permissions: getPermissions(),
  // },
  {
    mainModule: 'USER',
    module: 'ACTIONS',
    title: 'Actions',
    url: '/user/actions',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    module: 'OVERVIEW',
    title: 'Users',
    url: '/user/overview',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    module: 'PERFORMANCE',
    title: 'Performance',
    url: '/user/performance',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'ACTIVITY',
    title: 'Activity',
    url: '/user/user-behaviour/activity',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'LOGIN_LOG',
    title: 'Login Log',
    url: '/user/user-behaviour/login-log',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'BASIC_DETAILS',
    title: 'Basic Details',
    url: '/user/configuration/user-infomation',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'CHANGE_PASSOWRD',
    title: 'Change Password',
    url: '/user/configuration/change-password',
    permissions: getPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'PERMISSIONS',
    title: 'Permissions',
    url: '/user/configuration/permissions',
    permissions: getPermissions(),
  },
];

export const MODULES = {
  TRANSACTION: {
    QUOTE: {
      NAME: 'OFFER',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', COUNTER: 'COUNTER' },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED' },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: {
        CART: 'CART',
        WATCHLIST: 'WATCHLIST',
        NOTES: 'NOTES',
        REMINDER: 'REMINDER',
        SEEN_STONES: 'SEEN_STONES',
        ENQUIRY_OPEN: 'ENQUIRY_OPEN',
        ENQUIRY_CLOSE: 'ENQUIRY_CLOSE',
      },
    },
    OFFICE_VIEW: {
      NAME: 'OFFICE_VIEW',
      MODULE: {
        UPCOMING: 'UPCOMING',
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        EXPIRED: 'EXPIRED',
      },
    },
    BID: {
      NAME: 'BID',
      MODULE: { RUNNING: 'RUNNING', HISTORY: 'HISTORY', RESULTS: 'RESULTS', MYCLIENTBID: 'MYCLIENTBID' },
    },
    HOLD: {
      NAME: 'HOLD',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED', RELEASED: 'RELEASED' },
    },
    MEMO: {
      NAME: 'MEMO',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECT: 'REJECT',
        OFFICE: 'OFFICE',
        CONSIGNMENT: 'CONSIGNMENT',
      },
    },
    TRACK_SHIPMENT: { NAME: 'TRACK_SHIPMENT' },
  },
  // PRICING: {
  //   REVISE: { NAME: 'REVISE' },
  // },
  NOTIFICATIONS: {
    BULK_EMAIL: { NAME: 'BULK_EMAIL' },
    NEW: { NAME: 'NEW' },
    SEEN: { NAME: 'CLEARED' },
  },
  INVENTORY: {
    SINGLE_STONE: {
      NAME: 'SINGLE_STONE',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICKSEARCH: 'QUICK_SEARCH',
        NEWARRIVAL: 'NEW_ARRIVAL',
        UPCOMING: 'UPCOMING',
        SAVEDSEARCH: 'SAVED_SEARCH',
        CALIBRATED: 'CALIBRATED',
        PRICEREVISE: 'PRICEREVISE',
        CHECKIMAGE: 'CHECKIMAGE',
        SEARCH_HISTORY: 'SEARCH_HISTORY',
        DEMAND: 'MY_DEMAND',
        CLIENT_PREFERANCE: 'CLIENT_PREFERANCE',
        MATCHPAIR: 'MATCH_PAIR',
        SHOWSTONEREPORT: 'SHOW_STONE_REPORT',
        QUOTEDAY: 'OFFER_DAY',
        MATCHPAIRSEARCH: 'MATCHPAIRSEARCH',
        SEARCH_LAYOUT: 'SEARCH_LAYOUT',
      },
    },
    PARCEL_DIAMONDS: {
      NAME: 'PARCEL_DIAMONDS',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICK_SEARCH: 'QUICK_SEARCH',
        COLLECTION: 'COLLECTION',
        SAVED_SEARCH: 'SAVED_SEARCH',
        DEMAND: 'DEMAND',
        ENQUIRY: 'ENQUIRY',
        PRICE_CONFIGURATION: 'PRICE_CONFIGURATION',
      },
    },
    ANALYTICS: { NAME: 'ANALYTICS' },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        SYNC_WITH_GLOBAL_INVENTORY: 'SYNC_WITH_GLOBAL_INVENTORY',
        CREATE_COLLECTIONS: 'CREATE_COLLECTIONS',
        TERMS_DETAILS: 'TERMS_DETAILS',
        VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
        FEATURE_STONE: 'FEATURE_STONE',
        CARAT_RANGE: 'CARAT_RANGE',
        CUT_GROUP: 'CUT_GROUP',
        RAPNET_POLICY: 'RAPNET_POLICY',
        CHECK_IMAGE: 'CHECK_IMAGE',
        CERTIFICATE_UPLOAD: 'CERTIFICATE_UPLOAD',
        DIAMOND_COLUMN_SETTING: 'DIAMOND_COLUMN_SETTING',
        DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
        SHARE_STOCK: 'SHARE_STOCK',
        QUOTE_DAY: 'OFFER_DAY',
        CHECK_PACKET: 'CHECK_PACKET',
        CONFIG_MATCHPAIR: 'CONFIG_MATCHPAIR',
        CLIENT_ACTION: 'CLIENT_ACTION',
        MATCH_PAIR_CONFIGURE: 'MATCH_PAIR_CONFIGURE',
        BID_CONFIG: 'BID_CONFIG',
      },
    },
  },
  DASHBOARD: {
    DASHBOARD: { NAME: 'DASHBOARD' },
    ENQUIRY: { NAME: 'ENQUIRY' },
    INVENTORY: { NAME: 'INVENTORY' },
    SEARCH: { NAME: 'SEARCH' },
    ANALYSIS: {
      NAME: 'ANALYSIS',
      MODULE: {
        NEW_ARRIVAL: 'NEW_ARRIVAL',
        USER_ACTIVITY_ANALYSIS: 'USER_ACTIVITY_ANALYSIS',
        DAILY_VISIT_REPORT: 'DAILY_VISIT_REPORT',
      },
    },
  },
  USER: {
    OVERVIEW: { NAME: 'OVERVIEW' },
    ACTIONS: { NAME: 'ACTIONS' },
    PERFORMANCE: { NAME: 'PERFORMANCE' },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: { CHANGE_PASSOWRD: 'CHANGE_PASSOWRD', BASIC_DETAILS: 'BASIC_DETAILS', PERMISSIONS: 'PERMISSIONS' },
    },
    BEHAVIOUR: {
      NAME: 'BEHAVIOUR',
      MODULE: { LOGIN_LOG: 'LOGIN_LOG', ACTIVITY: 'ACTIVITY' },
    },
  },
  CLIENT: {
    OVERVIEW: { NAME: 'OVERVIEW' },
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        USER: 'USER',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: { HOLD: 'HOLD', MEMO: 'MEMO', DEMAND: 'DEMAND' },
    },
    TRANSACTION: {
      NAME: 'TRANSACTION',
      MODULE: {
        CART: 'CART',
        WATCHLIST: 'WATCHLIST',
        NOTE: 'NOTES',
        OFFICE_VIEW: 'OFFICE_VIEW',
        SEEN_STONES: 'SEEN_STONES',
        SUGGESTED_STOCK: 'SUGGESTED_STOCK',
        ACCURATE_STOCK: 'ACCURATE_STOCK',
      },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED' },
    },
    OFFER: {
      NAME: 'OFFER',
      MODULE: { PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED' },
    },
    SEARCH: {
      NAME: 'SEARCH',
      MODULE: { SAVEDSEARCH: 'SAVED_SEARCH', RECENT: 'RECENT_SEARCH', CLIENT_PREFERANCE: 'CLIENT_PREFERANCE' },
    },
    ANALYTICS: { NAME: 'ANALYTICS' },
    SUGGESTED_STOCK: { NAME: 'SUGGESTED_STOCK' },
  },
  ENQUIRY: {
    CONFIRM: { NAME: 'CONFIRM' },
    NOTES: { NAME: 'NOTES' },
    CUSTOMER_HISTORY: { NAME: 'CUSTOMER_HISTORY' },
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        USER: 'USER',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
  },
  TODO: {
    KYC: {
      NAME: 'KYC',
      MODULE: { CLOSED: 'CLOSED', IN_PROGRESS: 'INPROGRESS', PENDING: 'PENDING' },
    },
    SALES: { NAME: 'SALES' },
  },
  SHORTCUT: {
    USER: { NAME: 'USER' },
    KYC: { NAME: 'CLIENT_KYC' },
  },
  SETTING: {
    ACCOUNT: { NAME: 'MY_ACCOUNT' },
    NOTIFICATION: { NAME: 'NOTIFICATION' },
    TERMS: { NAME: 'TERMS_AND_CONDITIONS' },
    MY_SETTING: {
      NAME: 'MY_SETTING',
      MODULE: {
        MASTER: 'MASTER',
        SUB_MASTER: 'SUB_MASTER',
        CAREER: 'CAREER',
        CAREER_APPLICATIONS: 'CAREER_APPLICATIONS',
        FEEDBACK: 'FEEDBACK',
        ROLE_PERMISSION: 'ROLE_PERMISSION',
        HOME_SETTING: 'HOME_SETTING',
        EMAIL_CONFIGURATION: 'EMAIL_CONFIGURATION',
        PROJECT_SETTING: 'PROJECT_SETTING',
        CONTACT_DIRECTORY: 'CONTACT_DIRECTORY',
      },
    },
  },
  ACTIVITIES: {
    TodoList: { NAME: 'Todo' },
    CallLog: { NAME: 'CallLog' },
    EMAIL: { NAME: 'EMAIL' },
    TARGET: { NAME: 'TARGET' },
    NOTES: {
      NAME: 'NOTES',
      MODULE: { DUE: 'DUE', UPCOMING: 'UPCOMING', ARCHIVED: 'ARCHIVED' },
    },
    APPOINTMENT: {
      NAME: 'APPOINTMENT',
      MODULE: { PENDING: 'PENDING', COMPLETE: 'COMPLETE', CANCEL: 'CANCEL', REOPEN: 'REOPEN' },
    },
  },
};

export const MAINMODULE = {
  TRANSACTION: 'TRANSACTION',
  // PRICING: 'PRICING',
  NOTIFICATIONS: 'NOTIFICATION',
  ENQUIRY: 'ENQUIRY',
  INVENTORY: 'INVENTORY',
  DASHBOARD: 'DASHBOARD',
  USER: 'USER',
  CLIENT: 'CLIENT',
  TODO: 'TODO',
  SHORTCUT: 'SHORTCUTS',
  SETTING: 'SETTING',
  BIDSHOW: 'BIDSHOW',
};

export function getLoginPermission() {
  let permit = Storage.get('permission') || [];
  if (permit.filter((x) => x.mainModule && x.mainModule).length === 0) permit = [];
  if (!permit.length) permit = cloneDeep(PERMISSION);
  permit.forEach((val) => {
    if (!val.subModule && val.mainModule) {
      const fst = val.mainModule.split('_')[0];
      const main = invert(MAINMODULE)[val.mainModule] ? val.mainModule : invert(MAINMODULE)[fst] ? fst : val.mainModule;
      const mod = val.module;
      const sub = val.subModule
        ? val.subModule
        : invert(MAINMODULE)[fst] && val.mainModule.includes('_')
        ? val.mainModule.split('_').slice(1).join('_')
        : val.module;
      val.mainModule = main;
      val.subModule = sub;
      const obj = PERMISSION.filter((el) => el.mainModule === main && el.subModule === sub && el.module === mod);

      if (obj && obj[0]) {
        val.url = obj[0].url;
      }
    }
  });

  return permit;
}

export function getPermittedMainModuleList() {
  const PermissionData = getLoginPermission();
  let MenuData = [];
  if (PermissionData) {
    MENU.forEach((val) => {
      const modules = PermissionData.filter((el) => el.mainModule === val.permit)
        .map((x) => x.permissions.view || x.permissions.all)
        .filter((el) => el && el);
      if (modules.length) MenuData.push(val);
    });
  } else MenuData = MENU;
  return MenuData;
}

export function getPermittedSubModuleList(mainModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.subModule || x.module);
  return MenuData;
}

export function getPermittedModuleList(mainModule, subModule) {
  const PermissionData = getLoginPermission();

  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedModuleListWithoutSubmodule(mainModule, module) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.module === module)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.insert || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.insert || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedUpdateListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = find(PermissionData, { mainModule, subModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.update || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedDeleteFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.delete || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedExportList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.downloadExcel || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function permiableRoute(url) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url === url)
    .map((x) => x.permissions.view || x.permissions.all)
    .filter((el) => el && el);
  return MenuData.length ? true : false;
}

export const filterRoutesByModule = (routesList, module, subModule) => {
  if (!module || !isArray(routesList)) return routesList;

  const PermittedSubModuleList = subModule
    ? getPermittedModuleList(module, subModule)
    : getPermittedSubModuleList(module);

  if (isEmpty(PermittedSubModuleList) || !isArray(routesList)) return routesList;
  return routesList.filter((el) => PermittedSubModuleList.includes(el?.tab));
};

export function getModuleFromUrl() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url));
  const permit = MenuData[0] ? MenuData[0].permissions : {};

  if (permit.all) {
    Object.keys(permit).map((key) => {
      permit[key] = true;
    });
  }
  return permit;
}

export function downloadPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.downloadExcel || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}

export function printPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.printPDF || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}
