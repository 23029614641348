import { generatePath } from 'react-router-dom';

import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import xorWith from 'lodash/xorWith';
import { DateTime, Duration } from 'luxon';

import { _clone } from 'util/clone';
import { InvalidArgumentError } from 'util/errors';
import * as REGEX from 'util/regex';

import { LOCALE, CURRENCY, PRECISION, TIMEZONE_IANA, LUXON_FORMAT } from 'constants/Common';

export const clone = _clone;

export function runInDevelopment(callback) {
  if (![undefined, '', 'development'].includes(process.env.REACT_APP_ENV)) return;
  return callback();
}

export function logInfo(...args) {
  // eslint-disable-next-line no-console
  return runInDevelopment(() => console.info(...args));
}

export function logWarn(...args) {
  // eslint-disable-next-line no-console
  return runInDevelopment(() => console.warn(...args));
}

export function logError(...args) {
  // eslint-disable-next-line no-console
  return runInDevelopment(() => console.error(...args));
}

export function logTable(...args) {
  // eslint-disable-next-line no-console
  return runInDevelopment(() => console.table(...args));
}

export const catchError = (func, onError) => {
  const handleError = (error) => {
    logWarn(error);
    return onError?.(error);
  };

  try {
    const output = func?.();
    if (output?.constructor?.name !== 'Promise') return output;
    if (output?.catch?.constructor?.name !== 'Function') return output;
    return output?.catch?.(handleError);
  } catch (error) {
    return handleError(error);
  }
};

export const sleep = (ms = 100) => new Promise((r) => setTimeout(r, ms));

export const typeOf = (input, type) => input?.constructor?.name === (type ?? null);

export const isArray = (input) => typeOf(input, 'Array');

export const isObject = (input) => typeOf(input, 'Object');

export const isBoolean = (input) => typeOf(input, 'Boolean');

export const isString = (input) => typeOf(input, 'String');

export const isNumber = (input) => typeOf(input, 'Number') && !Number.isNaN(input) && Number.isFinite(input);

export const isFunction = (input) => typeOf(input, 'Function');

export const isAlphaNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.ALPHA_NUMERIC.STRICT : REGEX.ALPHA_NUMERIC.LOOSE).test(input);

export const isNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.NUMERIC.STRICT : REGEX.NUMERIC.LOOSE).test(input);

export const isIterable = (input) => isFunction(input?.[Symbol.iterator]);

export const isHTMLElement = (input) => input instanceof HTMLElement;

export const isEmpty = (input, options) => {
  options = { isEmpty: [], isNotEmpty: [], ...options };

  if (options.isEmpty?.includes?.(input)) return true;
  if (options.isNotEmpty?.includes?.(input)) return false;
  if ([undefined, null].includes(input)) return true;

  if (input?.constructor?.name === 'Array') return !input.length;
  if (input?.constructor?.name === 'Number') return Number.isNaN(input);
  if (input?.constructor?.name === 'Object') return !Object.keys(input).length;
  if (input?.constructor?.name === 'String') return !input.trim().length;

  return false;
};

export const isNotEmpty = (...args) => !isEmpty(...args);

export const pruneEmpty = (input, options) => {
  options = { clone: false, ...options };
  input = options.clone ? clone(input) : input;

  const prune = (current) => {
    current = (() => {
      if (isEmpty(current)) return undefined;
      if (isString(current)) return current?.trim?.();
      if (isArray(current)) return current.filter((value) => !isEmpty(prune(value), options));
      forOwn(current, (value, key) => {
        if (isEmpty(value, options) || ((isObject(value) || isArray(value)) && isEmpty(prune(value), options)))
          delete current[key];
      });
      return current;
    })();
    current = !isEmpty(current, options) ? current : undefined;
    return current;
  };
  return prune(input);
};

export const get = (input, path = '', defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), input);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === input ? defaultValue : result;
};

export const getKey = (input, pathGetter) => (isFunction(pathGetter) ? pathGetter(input) : get(input, pathGetter));

export const hasKey = (object, key) => isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);

export const keys = (...args) => catchError(() => Object.keys(...args)) ?? [];

export const values = (...args) => catchError(() => Object.values(...args)) ?? [];

export const forEach = (instance, iteratee) => {
  if (!isFunction(iteratee) && !isString(iteratee)) throw new InvalidArgumentError(2, [Function, String]);
  const callback = isString(iteratee) ? (obj) => get(obj, iteratee) : iteratee;
  return catchError(
    () => Array.prototype.forEach.call(instance, callback),
    () => [],
  );
};

export const map = (instance, iteratee) => {
  if (!isFunction(iteratee) && !isString(iteratee)) throw new InvalidArgumentError(2, [Function, String]);
  const callback = isString(iteratee) ? (obj) => get(obj, iteratee) : iteratee;
  return catchError(
    () => Array.prototype.map.call(instance, callback),
    () => [],
  );
};

export const sort = (list = [], desc = false) => {
  const returnValue = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return list.sort((curr, next) => {
    return curr < next ? returnValue.less : curr > next ? returnValue.more : 0;
  });
};

export const sortBy = (list = [], pathGetter, desc = false) => {
  const returnValue = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return list.sort((curr, next) => {
    const currVal = getKey(curr, pathGetter);
    const nextVal = getKey(next, pathGetter);
    return currVal < nextVal ? returnValue.less : currVal > nextVal ? returnValue.more : 0;
  });
};

export const unique = (list = []) => {
  return list.reduce((p = [], c) => {
    const indexFound = p.findIndex((item) => item === c);
    if (indexFound === -1) p.push(c);
    return p;
  }, []);
};

export const uniqueBy = (list = [], pathGetter) => {
  const output = list.reduce((p = [], c) => {
    const currentKey = getKey(c, pathGetter);
    const indexFound = p.findIndex((item) => getKey(item, pathGetter) === currentKey);
    if (indexFound === -1) p.push(c);
    return p;
  }, []);
  return output;
};

export const groupBy = (list = [], pathGetter) => {
  const output = {};
  list.forEach((item) => {
    const key = getKey(item, pathGetter);
    output[key] = output[key] ?? [];
    output[key].push(item);
  });
  return output;
};

export const returnIf = (func, ...args) => {
  return func(...args) ? args?.[0] : undefined;
};

export const returnIfNotEmpty = (input, defaultValue) => {
  return returnIf(isNotEmpty, input) ?? defaultValue;
};

export const isArrayEqual = (list1, list2) => isEmpty(xorWith(list1, list2, isEqual));

export const getCurrentTime = () => {
  return DateTime.local().setZone(TIMEZONE_IANA);
};

export const formatDateTime = memoize(
  (isoDateTime, format = LUXON_FORMAT.DATE_TIME) => {
    const dateTime = DateTime.fromISO(isoDateTime);
    return dateTime.isValid ? dateTime.toFormat(format) : undefined;
  },
  (...args) => JSON.stringify(args),
);

export const formatDateTimeZone = memoize(
  (isoDateTime, zone) => {
    const dateTime = DateTime.fromISO(isoDateTime);
    return dateTime.isValid ? dateTime.setZone(zone).toFormat(LUXON_FORMAT.DATE_TIME) : undefined;
  },
  (...args) => JSON.stringify(args),
);

export const formatDate = (isoDateTime, format = LUXON_FORMAT.DATE) => {
  return formatDateTime(isoDateTime, format);
};

export const formatTime = (isoDateTime, format = LUXON_FORMAT.TIME) => {
  return formatDateTime(isoDateTime, format);
};

export const formatDuration = (duration, format = LUXON_FORMAT.DURATION) => {
  return Duration.isDuration(duration) && duration?.isValid ? duration.toFormat(format) : undefined;
};

export const getDateTimeDiff = (startISO, endISO) => {
  return DateTime.fromISO(endISO).diff(DateTime.fromISO(startISO));
};

export const getFormattedDateTimeDiff = (startISO, endISO, format = LUXON_FORMAT.DURATION) => {
  return formatDuration(getDateTimeDiff(startISO, endISO), format);
};

export const castToNumber = (input, altOutput = undefined) => {
  const output = isString(input) ? Number(`${input}`.replace(/[^0-9.+-]/g, '')) : Number(input);
  return isNumber(output) ? output : altOutput;
};

export const formatNumber = (input, options = {}) => {
  if (isNumber(options)) options = { fractionLength: options };

  input = castToNumber(input);
  if (!isNumber(input)) return undefined;

  const { locale, fractionLength, ...rest } = { locale: LOCALE, ...options };
  const fractionDigits = fractionLength ?? `${input}`.split('.')?.[1]?.length ?? 0;
  const formatOptions = { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits, ...rest };
  return new Intl.NumberFormat(locale, formatOptions).format(input);
};

export const formatCurrency = (input, options = {}) => {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { style: 'currency', currency: CURRENCY, fractionLength: PRECISION, ...options });
};

export const formatDecimal = (input, options = {}) => {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { fractionLength: PRECISION, ...options });
};

export const parseDecimal = (input, fractionLength = PRECISION) => {
  if (!isNumber(Number(input))) return undefined;
  return Number(parseFloat(input).toFixed(fractionLength));
};

export const formatFloat = (input, fractionLength = PRECISION) => {
  if (!isNumber(Number(input))) return undefined;
  return parseFloat(input).toFixed(fractionLength);
};

export const formatInlineList = (list, options = {}) => {
  options = { separator: ',', returnString: true, removeDupes: true, allowAppend: false, ...options };

  if (isArray(list)) list = list.join(options.separator);
  if (!isString(list)) return list;

  let output = `${list}`.replace(/[\s,]+/gm, options.separator).split(options.separator);
  output = output.filter(
    (value, index) => !isEmpty(value) || (options.allowAppend && index && output?.length === index + 1),
  );
  options.removeDupes = options.allowAppend
    ? isEmpty(output[output.length - 1]) && options.removeDupes
    : options.removeDupes;
  output = options.removeDupes ? unique(output) : output;
  output = options.returnString ? output.join(options.separator) : output;

  return output;
};

export const padArray = (list, length, fillWith) => {
  return list.concat(Array(length).fill(fillWith)).slice(0, length);
};

export const reduceTotal = (list, key) => {
  if (!isArray(list) || isEmpty(list)) return 0;
  const numList = key === undefined ? list.map(Number) : list.map((item) => Number(item?.[key]));
  return numList.filter(isNumber).reduce((pv, cv) => (pv += cv), 0);
};

export const classNames = (list) => {
  return list.filter(isString).join(' ');
};

export const upperFirst = (input, locale = LOCALE) => {
  if (!isString(input)) return '';
  return input.replace(/(^[a-z])/, (match) => match.toLocaleUpperCase(locale));
};

export const lowerFirst = (input, locale = LOCALE) => {
  if (!isString(input)) return '';
  return input.replace(/(^[a-z])/, (match) => match.toLocaleLowerCase(locale));
};

export const upperCase = (input, locale = LOCALE) => {
  if (!isString(input)) return '';
  return input.toLocaleUpperCase(locale);
};

export const lowerCase = (input, locale = LOCALE) => {
  if (!isString(input)) return '';
  return input.toLocaleLowerCase(locale);
};

export const titleCase = (input, locale = LOCALE) => {
  if (!isString(input)) return '';
  const list = input.split(/([ :–—-])/);
  const words = list.map((current, index, list) => {
    return (
      // Check for small words
      current.search(/^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i) > -1 &&
        // Skip first and last word
        index !== 0 &&
        index !== list.length - 1 &&
        // Ignore title end and subtitle start
        list[index - 3] !== ':' &&
        list[index + 1] !== ':' &&
        // Ignore small words that start a hyphenated phrase
        (list[index + 1] !== '-' || (list[index - 1] === '-' && list[index + 1] === '-'))
        ? current.toLocaleLowerCase(locale)
        : current.substr(1).search(/[A-Z]|\../) > -1 // Ignore intentional capitalization
        ? current
        : list[index + 1] === ':' && list[index + 2] !== '' // Ignore URLs
        ? current
        : current.replace(/([A-Za-z0-9\u00C0-\u00FF])/, (match) => match.toLocaleUpperCase(locale)) // Capitalize the first letter
    );
  });
  return words.join('');
};

export const objectToQueryString = (object) => {
  return catchError(
    () =>
      `?${Object.entries(object)
        .map(([key, value]) => `${key}=${!isEmpty(value) && isFunction(value?.toString) ? value.toString() : ''}`)
        .join('&')}`,
    () => '',
  );
};

export const queryStringToObject = (search = window.location.search) => {
  return catchError(
    () => {
      const urlParams = new URLSearchParams(search);
      return Object.fromEntries(urlParams.entries());
    },
    () => {},
  );
};

export const getUserName = memoize(
  (input, replace = '-') => {
    if (hasKey(input, 'user')) input = input?.user;
    const name = [input?.firstName, input?.lastName].filter(isNotEmpty);
    return titleCase(!isEmpty(name) ? name.join(' ') : input?.name ?? input?.username ?? replace);
  },
  (user, replace) => `${user?.firstName}${user?.lastName}${user?.name}${user?.username}${replace}`,
);

export const generateRoutePath = (path, params) => {
  return catchError(
    () => generatePath(path, params),
    () => path.split(':')?.[0] ?? '',
  );
};

export const parseFilenameString = (input) => {
  if (!isString(input)) return;
  const index = input.lastIndexOf('.');
  const filename = input.substring(0, index);
  const ext = input.substring(index + 1);
  return { filename, ext };
};

export const capitalize = (input) =>
  input?.replace(/_/g, ' ')?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));

export const sortArrayByKey = (key = 'id', desc = false) => {
  if (!isString(key)) return undefined;
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
};
