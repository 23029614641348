import { SET_CLIENT, CLIENT_FETCH_START, CLIENT_FETCH_SUCCESS, CLIENT_FETCH_ERROR } from 'constants/ActionTypes';

const INIT_STATE = {
  clientDetail: '',
  clientDetailList: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CLIENT:
      return { ...state, clientDetail: action.payload };

    case CLIENT_FETCH_START:
      return { ...state, loading: true };

    case CLIENT_FETCH_SUCCESS:
      return { ...state, clientDetailList: action.payload, loading: false };

    case CLIENT_FETCH_ERROR:
      return { ...state, clientDetailList: [], loading: false };

    case '@@RESET':
      return { ...INIT_STATE };

    default:
      return state;
  }
};
