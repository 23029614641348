import { PermissionService } from 'services/PermissionService';
import { PREFIX_URL_WITH_SLASH as URL } from 'constants/CommonUrl';

export const MENU = [
  { path: `${URL}/dashboard`, name: 'Home', svg: 'Dashboard', permit: 'DASHBOARD' },
  { path: `${URL}/inventory`, name: 'Inventory', svg: 'Master', permit: 'INVENTORY' },
  { path: `${URL}/client`, name: 'Clients', svg: 'Account', permit: 'CLIENT' },
  // { path: `${URL}/enquiry`, name: 'Enquiry', svg: 'Enquiry', permit: 'ENQUIRY' },
  { path: `${URL}/transaction`, name: 'Transactions', svg: 'Transactions', permit: 'TRANSACTION' },
  // { path: `${URL}/pricing`, name: 'Pricing', svg: 'Pricing', permit: 'PRICING' },
  { path: `${URL}/notification`, name: 'Notifications', svg: 'Notification', permit: 'NOTIFICATION' },
  { path: `${URL}/stock-upload`, name: 'Stock Upload', svg: 'Media', permit: 'STOCK_UPLOAD' },
  { path: `${URL}/activity`, name: 'Activity', svg: 'Todo', permit: 'ACTIVITIES' },
  // { path: `${URL}/bid`, name: 'Bid', svg: 'Bid', permit: 'BIDSHOW' },
  // { path: `${URL}/todo`, name: 'Todo', svg: 'Todo', permit: 'TODO' },
  { path: `${URL}/user`, name: 'Users', svg: 'Account', permit: 'USER' },
  // { path: `${URL}/crm`, name: 'CRM', svg: 'Account', permit: 'CRM' },
  // { path: `${URL}/utility`, name: 'Utility', svg: 'Account', permit: 'UTILITY' },
];

export function getMenuLinks() {
  const permissionTree = PermissionService.getTree() ?? [];
  const mainModuleList = Object.keys(permissionTree).filter((module) => {
    const modulePermissions = PermissionService.getPermission(module);
    return modulePermissions?.allow;
  });
  return MENU.filter(({ permit }) => mainModuleList.includes(permit));
}
