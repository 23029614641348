const initialState = {
  type: 'all',
  isActive: true,
};

export const userActionTypes = {
  SET_USER_FILTER: 'SET_USER_FILTER',
  RESET_USER_FILTER: 'RESET_USER_FILTER',
};

export const UserFilterActions = {
  setUserFilter: (filters) => ({
    type: userActionTypes.SET_USER_FILTER,
    payload: filters,
  }),
  resetFilter: () => ({
    type: userActionTypes.RESET_USER_FILTER,
  }),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_USER_FILTER:
      return { ...state, ...action.payload };

    case '@@RESET':
    case userActionTypes.RESET_USER_FILTER:
      return { ...initialState };

    default:
      return state;
  }
};
