import { Action } from 'services/action';
import { Storage } from 'services/storage';

import { isArray, isEmpty, isObject } from 'util/utils';

export class MasterService {
  static data = [];
  static tree = [];

  static get updateTreeEvent() {
    return new Action('@master/update-tree');
  }
  static get updateListEvent() {
    return new Action('@master/update-list');
  }

  static setData() {
    this.data = Storage.get('dn-master') ?? {};
    this.updateListEvent.emit(this.data);
  }

  static getData() {
    if (!isObject(this.data) || isEmpty(this.data)) this.setData();
    return this.data;
  }

  static getTree() {
    if (!isObject(this.tree) || isEmpty(this.tree)) this.setTree();
    return this.tree;
  }

  static setTree() {
    this.tree = Object.fromEntries(
      Object.entries(this.getData()).map(([key, list]) => {
        const map = { byId: {}, byCode: {} };

        list.forEach((item) => {
          const idList = isArray(item.id) ? item.id : [item.id];
          const codeList = isArray(item.code) ? item.code : [item.code];
          idList.forEach((id) => void (map.byId[id] = { ...item, _id: id }));
          codeList.forEach((code) => void (map.byCode[code] = { ...item, _id: item?.id?.[0] }));
        });

        return [key, map];
      }),
    );
    this.updateTreeEvent.emit(this.tree);
  }

  static getList(key) {
    return this.getData()?.[key];
  }

  static getMapById(key) {
    return this.getTree()?.[key]?.byId;
  }

  static getMapByCode(key) {
    return this.getTree()?.[key]?.byCode;
  }

  static addListeners() {
    MasterService.setTree();
    void window.__MasterListeners?.forEach?.((unlisten) => unlisten?.());
    window.__MasterService = MasterService;
    window.__MasterListeners = [Storage?.listen?.('dn-master', () => MasterService.setTree())];
  }
}
